@import "~antd/dist/antd.less";
@import "~antd/dist/antd.dark.less";

.ant-collapse-header,
.ant-collapse-content {
    border-bottom: 1px solid #434343;
}
.ant-collapse-content-box {
    background-color: #262626;
    padding: 16px !important;
}

.flt-f-c-btns {
    button {
        width: 100px;
        margin-right: 10px;
    }
}
.divider-nogap {
    margin: 12px 0 !important;
}

.disabled-filter-panel {
    height: 57px;
    // background: #fafafa;
    background: #2d2d2d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    border-bottom: 1px solid #434343;

    .title {
        font-size: 16px;
        font-weight: 500;
    }
}

.ant-form-vertical {
    .ant-form-item {
        margin-bottom: 0px;

        .ant-form-item-label {
            padding-bottom: 2px;
        }
    }
}

.counter-panel {
    min-width: 220px;
    text-align: right;
}

@primary-color: #E35728;@collapse-header-bg: #1d1d1d;