@import "~antd/dist/antd.less";
@import "~antd/dist/antd.dark.less";

.form-select {
    margin-bottom: 12px;
}

.form-input-tree {
    padding: 15px 10px;
    border: 1px solid #d9d9d9;
}

@primary-color: #E35728;@collapse-header-bg: #1d1d1d;