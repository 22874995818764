@import "~antd/dist/antd.less";
@import "~antd/dist/antd.dark.less";

.row-action-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.flexi-selectedRow-Container {
    padding: 4px 8px;

    .ant-tag {
        margin-top: 4px;
        margin-bottom: 4px;
    }
}

.flexi-datatable {
    &.bordered {
        border: 1px solid #8f8f8f;
    }

    .ant-table-pagination {
        margin-right: 16px !important;
    }

    .ant-table-expanded-row {
        .ant-table-pagination {
            margin-top: 32px !important;
            margin-bottom: 0px !important;
        }
    }

    .ant-table-body {
        overflow: auto !important;
        max-height: none !important;
    }
}

.flexi-table {
    .ant-table-pagination {
        padding-left: 20px;
        padding-right: 20px;
    }

    .ant-table-body {
        overflow: auto !important;
        max-height: none !important;
    }
}

@primary-color: #E35728;@collapse-header-bg: #1d1d1d;