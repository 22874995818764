.site-main-content {
    .site-page-header {
        padding: 0 !important;
        margin-bottom: 0px !important;

        .ant-breadcrumb {
            li {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .ant-page-header-heading {
            background-color: #141414;
            padding: 10px 20px;
        }
    }

    .page-content {
        border-top: 1px solid #313131 !important;
    }
}

@primary-color: #E35728;@collapse-header-bg: #1d1d1d;