body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
    height: 100%;
}

@import "~antd/dist/antd.less";
@import "~antd/dist/antd.dark.less";

.main-layout {
    min-height: 100vh !important;
}

.shadow-effect {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 40px 24px;
    // background-color: #283447 !important;

    .left-panel {
        display: flex;
        flex-direction: row;
        align-items: center;

        .collapse-btn {
            border: none;
            background: transparent;
            font-size: 24px;
            line-height: 24px;
            padding: 0px 0px;
        }
    }

    .right-panel {
        padding: 10px 10px 10px 10px;

        .setting {
            button:first-child {
                padding-left: 0;
            }

            button:last-child {
                color: #ffffff;
                border-radius: 30px !important;
                background: #c1c1c1;
            }
            .ant-btn-icon-only {
                border: none;
            }
        }

        .user-info-panel {
            display: flex;
            flex-direction: column;
            line-height: 1.3;
            justify-content: center;
            align-items: flex-end;

            span:first-child {
                font-weight: bold;
                font-size: 16px;
                color: #e35728;
            }

            span:last-child {
                color: #ffffff;
            }
        }
    }
}

.logo-container {
    //cursor: text !important;
    min-height: 52px !important;

    .logo {
        width: 20px;
        height: 20px;
        cursor: none;
    }

    span {
        font-weight: bold;
        font-size: 16px;
        color: #ffffff !important;
    }
}

.ant-menu.ant-menu-dark {
    .ant-menu-item-selected {
        background-color: #000 !important;
        border-left: 5px solid #e35728;

        &.logo-container {
            background-color: transparent !important;
            border-left: none;
        }
    }
}

.siderbar-container {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0.4375rem 0rem 0.625rem -0.3125rem rgba(0, 0, 0, 0.4);

    .ant-menu-dark {
        ul.ant-menu-sub {
            background: #2f3e46 !important;
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        }

        .ant-menu-item-group {
            .ant-menu-item-group-title {
                font-size: 13px;
                color: #959595;
                background: #34454d;
            }
        }
    }

    .ant-menu-item-icon,
    .ant-menu-title-content,
    .ant-menu-submenu-arrow,
    .ant-menu-item .anticon {
        color: white;
    }
    .ant-menu-item {
        padding-left: 25px !important;
        color: white;
    }
}

.main-content {
    padding: 24px 24px 0px;
}

.row-dropdow-panel {
    .ant-dropdown-menu-item {
        padding-left: 0px;
        padding-right: 0px;
        button {
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

.step-btns-group {
    border-top: 1px solid #313131;
    padding-top: 20px;

    Button {
        margin-right: 10px;
        width: 100px;
    }
}

.setting-overlay-panel {
    width: 150px;
}

@primary-color: #E35728;@collapse-header-bg: #1d1d1d;