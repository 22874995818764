@import "~antd/dist/antd.less";
@import "~antd/dist/antd.dark.less";

.card-box-container {
    padding: 24px 0px;

    .inner-container {
        display: flex;
        flex-direction: column;
        background-color: #282828;
        position: relative;
        border-radius: 0.5rem;

        .card-box-header {
            background: linear-gradient(-138deg, #f37a20 0, #ef5123 55%, #ee3824 100%);
            color: #ffffff;
            padding: 12px 16px;
            margin-top: -24px;
            margin-left: 10px;
            margin-right: 10px;
            font-size: 18px;
            font-weight: bold;
            border-radius: 0.5rem;
            // box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(0 187 212 / 40%);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            display: flex;
            justify-content: space-between;

            .left-panel {
            }
            .right-panel {
            }
        }

        .card-box-content {
            padding-top: 24px;
        }
    }
}

@primary-color: #E35728;@collapse-header-bg: #1d1d1d;