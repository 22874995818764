@import "~antd/dist/antd.less";
@import "~antd/dist/antd.dark.less";

.login-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .background-image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        z-index: 1;
    }

    .login-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: #282828;
        width: 320px;
        padding: 30px;
        border-radius: 10px;
        z-index: 10;
        box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 0.2), 1rem 1rem 0.625rem -0.3125rem rgb(0 0 0 / 0.3);

        & > form {
            margin-top: 30px;
        }
    }
}

@primary-color: #E35728;@collapse-header-bg: #1d1d1d;